<template>
  <IonPage>
    <div class="h-screen w-screen bg-white z-9999 place-items-center mt-12">
      <div class="flex flex-col justify-center items-center">
        <Carousel :slides="codes" />
      </div>
    </div>
  </IonPage>
</template>

<script>
import Carousel from '../../components/Carousel/Carousel.vue';
import Tutoruu from '../../assets/Tutoruu.png';
import Posting from '../../assets/Posting.png';
import comment from '../../assets/comment.png';
import Attachment from '../../assets/Attachment.png';
import { IonPage } from "@ionic/vue";
export default {
  components: {
    Carousel,
    IonPage
  },
  data() {
    return {
      firstTime: false,
      codes: [
        { img: Tutoruu, title: 'Introducing Tutoruu Community', subtitle: '' },
        { img: Posting, title: 'Ask/Post Anything', subtitle: 'You can now ask anything to your own University Community' },
        { img: comment, title: 'Interact with your Community', subtitle: 'Comment or like on other posts at your University' },
        { img: Attachment, title: 'Find Peer-Tutors &  Class Material', subtitle: 'Save notes or pdf’s you find here or even find High-achieving students to help you with your class ' }],
    };
  },
}
</script>

