<template>
    <div class="h-96 flex flex-col items-center space-y-10">
        <div class="flex flex-col items-center justify-center ">
            <img :src="image" />
        </div>
        <div class="flex flex-col  items-center justify-center gap-y-3">
            <p class=" font-poppins text-2xl text-center align-middle font-bold "> {{ title }}</p>
            <p class="font-poppins text-base font-normal text-center w-72"> {{ subtitle }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['image', 'title', 'subtitle']
}
</script>

