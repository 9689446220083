<template>
  <div class="flex flex-row justify-center mb-6 items-center">
    <button
      class="carousel-indicator-item"
      :class="{ active: currentIndex === index }"
      v-for="(item, index) in total"
      :key="index"
      @click="$emit('switch', index)"
    ></button>
  </div>
</template>

<script>
export default {
  emits: ["switch"],
  props: ["total", "currentIndex"],
};
</script>

<style scoped>
.carousel-indicators {
  bottom: 3em;
  z-index: 2;
}
.carousel-indicator-item {
  width: 10px;
  height: 10px;
  border: none;
  background: rgb(250, 128, 5);
  opacity: 0.5;
  margin: 0.2em;
  border-radius: 50%;
  cursor: pointer;
}
.active {
  opacity: 1;
}
</style>